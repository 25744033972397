import {
  BrowserRouter, 
  Routes,
  Route
} from 'react-router-dom'
import Layout from './layout/Layout'
import Home from './components/pages/Home'
import AboutUs from './components/pages/AboutUs'
import ContactUs from './components/pages/ContactUs'

import './Landing.css';

const LandingPage = () => {
  return (
    <div className="body-wrap">
      <BrowserRouter>
        <Layout >
          <Routes>
            <Route path="/" element={<Home />}> </Route>
            <Route path="/AboutUs" element={<AboutUs />}> </Route>
            <Route path="/ContactUs" element={<ContactUs />}> </Route>
          </Routes>
        </Layout>
      </BrowserRouter>
    </div>
  );
};

export default LandingPage;

