export function isNumeric(n: any) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

export function getFormattedDate(date: string | Date) {
    let dateValue = new Date(date);
    let year = dateValue.getFullYear();
    let month = (1 + dateValue.getMonth()).toString().padStart(2, '0');
    let day = dateValue.getDate().toString().padStart(2, '0');
  
    return month + '/' + day + '/' + year;
}

export function generateRandomUserName(): string {
  const possibleChars = 'abcdefghijklmnopqrstuvwxyz0123456789[]!?';
  let userName = '';
  const userNameLength = Math.floor(Math.random() * 15);
  for (let i = 0; i < userNameLength; i++) {
    const randomIndex = Math.floor(Math.random() * possibleChars.length);
    userName += possibleChars.charAt(randomIndex);
  }
  return userName;
}