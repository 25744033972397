import { Typography, Divider } from 'antd';
import BreakDownForm from './BreakDownForm';

function BreakDownNew () {

  return (
    <>
    <Typography.Title level={5} className="breakdown-title">Create Break Down Sheet</Typography.Title>
    <Divider />
    <BreakDownForm
      setParentIsDirty={()=>{}} 
      refreshParent={()=>{}}
    />
    </>
  );
}

export default BreakDownNew;
