import {
  BrowserRouter, 
  Routes,
  Route
} from 'react-router-dom'

import MainLayout from "./components/layout/MainLayout";
import { routes } from "./routes";

const MainApp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
            {routes}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default MainApp;

