import React from 'react'

const AboutUs = () => {
    return (
        <React.Fragment>
            <section className="content-container">
                <div className="textArea"> 
                    <p style={{ textAlign: 'left' }}>
                        Welcome to our website! We are a team of passionate individuals 
                        dedicated to providing useful tools and services to medical 
                        professionals, dentists, and small business owners.
                    </p>

                    <p style={{ textAlign: 'left' }}>
                        Our journey began when we realized that many doctors and dentists 
                        struggled with fee schedules and the process of setting prices for 
                        their services. We knew that we could make a difference by 
                        providing easy-to-use fee schedule tools that could save doctors 
                        time and money while helping them to stay competitive in their 
                        respective markets.
                    </p>

                    <p style={{ textAlign: 'left' }}>
                        As we continued to work on our fee schedule tools, we realized 
                        that there was a need for more services to help medical 
                        professionals and small business owners thrive. Our team worked 
                        tirelessly to create a suite of services that would be beneficial 
                        to these groups, including practice management tools, marketing 
                        services, and more.
                    </p>

                    <p style={{ textAlign: 'left' }}>
                        Our commitment to excellence is reflected in everything we do. We 
                        take pride in delivering exceptional customer service and providing 
                        tools and services that meet the unique needs of our clients. 
                        Our goal is to make it easier for medical professionals and small 
                        business owners to focus on what they do best - providing 
                        high-quality care and growing their businesses.
                    </p>

                    <p style={{ textAlign: 'left' }}>
                        Thank you for choosing our website as your go-to resource 
                        for fee schedule tools and other services. We look forward 
                        to helping you achieve your goals and succeed in your 
                        respective fields.
                    </p>
                </div>
            </section>
        </React.Fragment>
    )
}

export default AboutUs;