import PropTypes from 'prop-types';

import { Modal} from 'antd';

import FeeScheduleMeta from './FeeScheduleMeta';

function FeeScheduleSaveAsModal(props: any)  {
  const { 
    showModal, 
    handleCancel, 
    form, 
    handleOk, 
    } = props;

  const handleOkClick = () => {
    // submit the form
    form.submit();
    handleOk()
  };

  const handleMetaFormChange = () => {
    // place holder
  };

  return (
    <Modal
    title="Enter Fee Schedule Meta Data"
    open={showModal}
    onCancel={handleCancel}
    onOk={handleOkClick}
    width={650}
  >
    <FeeScheduleMeta form={form} handleMetaFormChange={handleMetaFormChange}/>
  </Modal>
  );
};

FeeScheduleSaveAsModal.propTypes = {
    showModal: PropTypes.bool.isRequired, 
    handleCancel: PropTypes.func.isRequired, 
    form: PropTypes.object.isRequired, 
    handleOk: PropTypes.func.isRequired, 
  };

export default FeeScheduleSaveAsModal;