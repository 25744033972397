import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Select, Form, Input, Button, Divider, Space, message, Modal, Typography } from 'antd';
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { mainAppContext } from '../../App';
import { apiUrlBase, userTypes } from '../../configs/constants';

import { UserProfile } from '../../types/userProfile';
import usePrompt from '../../components/layout/usePrompt';

const { confirm } = Modal;
const { Title } = Typography;

function UserManagementPage() {

  const {user} = useContext(mainAppContext);

  const [users, setUsers] = useState<UserProfile []>([]);
  const [selectedUser, setSelectedUser] = useState<UserProfile>();
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [isDirty, setIsDirty] = useState(false);

  const [form] = Form.useForm();

  const config = {
    headers: {
      Authorization: `Bearer ${user}`,
      'Content-Type': 'application/json',
    },
  };

  const handleFormChange = () => {
    setIsDirty(true);
  };

  useEffect(() => {
    // Load all users from the database via an axios call and only once
  
    setSelectedValue("-- Please Select a User--");

    let apiUrl = `${apiUrlBase}/users`;
  
    axios.get(apiUrl, config)
      .then(response => setUsers(response.data))
      .catch(error => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  function replaceUser(newUser: UserProfile): UserProfile[] {
    const index = users.findIndex((user) => user.id === newUser.id);
    
    if (index === -1) {
      // item not found, return original array
      return users;
    }
    
    const newUsers = [...users]; // make a copy of the array
    newUsers[index] = newUser; // replace the item at the index
    
    return newUsers;
  }
    
  function removeUser(id: string): UserProfile[] {
    const index = users.findIndex((user) => user.id === id);
    
    if (index === -1) {
      // item not found, return original array
      return users;
    }
    
    const newUsers = [...users]; // make a copy of the array
    newUsers.splice(index, 1); // remove the item at the index
    
    return newUsers;
  }

  function handleUserSelect(value: any) {
    // Update the selected user when the drop-down value changes
    const user = users.find((user) => user.id === value);
    if (!user) return;
    setSelectedUser(user);
    setSelectedValue(user.email);
    form.setFieldsValue(user); 
    setIsDirty(false);
  }

  const handleSubmit = async () => {
    setLoading(true);

    const postData = {
      id: selectedUser!.id,   //cannot change
      userImage: selectedUser?.userImage,   //cannot change
      userType: form.getFieldValue('userType'), 
      fullName: form.getFieldValue('fullName'),
      email: form.getFieldValue('email'),
      userPhone: form.getFieldValue('userPhone'),
      userAddress: form.getFieldValue('userAddress'),
      screenId: form.getFieldValue('screenId'),
    };
    const apiUrl = `${apiUrlBase}/users/admin/${selectedUser?.id}`;
    await axios.put(apiUrl, postData, config);
    setUsers(replaceUser(postData));
    setLoading(false);
    setIsDirty(false);
  }

  const handleDelete = () => {
    confirm({
      title: "Are you sure you want to delete this user?",
      icon: <ExclamationCircleOutlined />,
      okType: "danger",
      async onOk() {
        const apiUrl = `${apiUrlBase}/users/${selectedUser!.id}`;
        await axios.delete(apiUrl, config);
        setUsers(removeUser(selectedUser!.id));
        setSelectedValue("-- Please Select a User--");
        setSelectedUser(undefined);
        message.success("User is deleted successfully");
        setIsDirty(false);
      },
    });
  };

  // Custom validation function for phone number
  const validatePhoneNumber = (rule: any, value: any, callback: any) => {
    if (!value) {
      callback();
    } else if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value)) {
      callback('Please enter a valid phone number!');
    } else {
      callback();
    }
  };

  const promptMessage = "Are you sure you want to leave? You have unsaved changes.";
  usePrompt(promptMessage, isDirty);
  
  return (
    <div style={{ padding: '0px' }}>
      <Title level={5}>User Management</Title>
      <Select id="user-select" value={selectedValue} onChange={handleUserSelect}
        style={{ width: 400 }} placeholder="-- Please Select a User--">
        {users.map(user => (
          <Select.Option key={user.id} value={user.id}>{user.email}</Select.Option>
        ))}
      </Select>
      {selectedUser && (
        <div>
          <Divider />
          <Form 
            form={form}
            onFinish={handleSubmit} 
            layout="horizontal"
            style={{ maxWidth: 400 }}
            onValuesChange={handleFormChange}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 20 }}
            size="middle"
            labelAlign='right'
          >
          <Form.Item label="ID" name="id" rules={[{ required: true, message: 'Please enter user ID' }]}>
            <Input disabled={true}  placeholder="User ID"/>
          </Form.Item>

          <Form.Item label="Name" name="fullName" rules={[{ required: true, message: 'Please enter your name' }]}>
            <Input style={{ width: '50%' }} placeholder="Name" />
          </Form.Item>

          <Form.Item label="Screen ID" name="screenId" rules={[{ required: true, message: 'Please enter your screen ID' }]}>
            <Input disabled={true} style={{ width: '50%' }} />
          </Form.Item>

          <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please enter a valid email', type: 'email' }]}>
            <Input placeholder="User email"/>
          </Form.Item>
          
          <Form.Item label="Type" name="userType" rules={[{ required: true, message: 'Please enter user type' }]}>
            <Select
                style={{ width: 200 }} placeholder="-- Please Select User Type--">
                {userTypes.map(type => (
                  <Select.Option key={type.id} value={type.id}>{type.type}</Select.Option>
                ))}
            </Select>
          </Form.Item>
          
      <Form.Item label="Phone"  name='userPhone' rules={[{validator: validatePhoneNumber}]}>
        <Input style={{ width: '50%' }} placeholder="888-888-8888" />
      </Form.Item>

          <Form.Item label="Address" name="userAddress" rules={[{ required: false, message: 'Please enter your address' }]}>
            <Input placeholder="User Address"/>
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" ghost disabled={!isDirty} htmlType="submit" loading={loading}>
                Update User
              </Button>
              <Button type="primary" ghost danger onClick={handleDelete}>
                Delete User
              </Button>
            </Space>
          </Form.Item>
        </Form>
        </div>
      )}
    </div>
  );
}

export default UserManagementPage;
