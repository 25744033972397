import React, { useState, useEffect } from 'react';
import { Form, Radio, Select, Table} from 'antd';
import type { RadioChangeEvent } from 'antd';

import { FeeScheduleRecordType, FeeScheduleSelectType, FeeScheduleType } from '../../types/feeSchedule';
import { INSURANCE_TYPE } from '../../configs/constants';
import { getFormattedDate } from '../../util/util';

function DownloadSelect (props: any)  {
  const {
    allFeeSchedules,  // passed from parent
    initialFeeScheduleId,  // passed from parent
    setSelectedRecord, // pass to parent
    setFeeSchduleDataSource, // pass to parent
  } = props;

  const [payorIdOptions, setPayorIdOptions] = useState<FeeScheduleRecordType[]>([]);
  const [groupNoOptions, setGroupNoOptions] = useState<FeeScheduleRecordType[]>([]);
  const [selectTableData, setSelectTableData] = useState<FeeScheduleSelectType[]>([]);

  const [selectedRowKey, setSelectedRowKey] = useState<React.Key []>([]);

  const [form] = Form.useForm();

  let columns = [
    {
      title: 'Carrier',
      dataIndex: 'insCarrier',
      key: 'insCarrier',
    },
    {
      title: 'Plan Name',
      dataIndex: 'insPlanName',
      key: 'insPlanName',
    },
    {
      title: 'Group Name',
      dataIndex: 'insGroupName',
      key: 'insGroupName',
    },
    {
      title: 'Effective Date',
      key: 'effectiveDate',
      dataIndex: 'effectiveDate',
    },
    {
        title: 'Expiration Date',
        key: 'expirationDate',
        dataIndex: 'expirationDate',
      },
    {
      title: 'Comments',
      key: 'comments',
      dataIndex: 'comments',
    },
    {
      title: 'Contributor',
      key: 'contributor',
      dataIndex: 'contributor',
    },
    {
      title: 'Modified Date',
      key: 'modifiedDate',
      dataIndex: 'modifiedDate',
    },
  ];

  const onInsTypeChange = (e: RadioChangeEvent) => {

    const data = allFeeSchedules.filter((feeSchedule: FeeScheduleRecordType)=>
        feeSchedule.feeScheduleMeta.insType===e.target.value);
   
    setPayorIdOptions(data);
    setGroupNoOptions([]);
    setSelectTableData([]);
    setSelectedRecord({});
    setFeeSchduleDataSource([]);
    form.setFieldValue('payorId', 'Select Payor ID');
    form.setFieldValue('insGroupNo', 'Select Group#');
  };

  const getPayorIdOptions = () => {
    const uniqueData = payorIdOptions.map((feeSchedule: FeeScheduleRecordType) => feeSchedule.feeScheduleMeta.payorId)
        .filter((value, index, self) => self.indexOf(value) === index);

    return uniqueData.map((item) => (
        <Select.Option key={item} value={item}>
            {item}
        </Select.Option>
    ));
  };

  const onPayorIdChange = (value: string) => {

    const data = payorIdOptions.filter((feeSchedule: FeeScheduleRecordType)=>
    feeSchedule.feeScheduleMeta.payorId===value);

    setGroupNoOptions(data);
    setSelectTableData([]);
    setSelectedRecord({});
    setFeeSchduleDataSource([]);
    form.setFieldValue('insGroupNo', 'Select Group#');
  };
  
  const getGroupOptions = () => {
    const uniqueData = groupNoOptions.map((feeSchedule: FeeScheduleRecordType) => feeSchedule.feeScheduleMeta.insGroupNo)
  .filter((value, index, self) => self.indexOf(value) === index);

    return uniqueData.map((item) => (
        <Select.Option key={item} value={item}>
            {item}
        </Select.Option>
    ));
  };

  const onGroupNoChange = (value: string) => {
    const data = groupNoOptions.filter((feeSchedule: FeeScheduleRecordType)=>
    feeSchedule.feeScheduleMeta.insGroupNo===value);

    // format data for table display
    const newData: FeeScheduleSelectType [] | any = [];

    for(let i=0; i<data.length; i++){
        const formattedData : FeeScheduleSelectType | any = {};
        formattedData.key = data[i].id;
        formattedData.comments = data[i].feeScheduleMeta.comments;
        formattedData.contributor = data[i].contributor;
        formattedData.contributorId = data[i].contributorId;
        formattedData.modifiedDate = getFormattedDate(data[i].modifiedDate);
        formattedData.insCarrier= data[i].feeScheduleMeta.insCarrier;
        formattedData.id = data[i].id;
        formattedData.insPlanName = data[i].feeScheduleMeta.insPlanName;
        formattedData.insGroupName = data[i].feeScheduleMeta.insGroupName;
        formattedData.effectiveDate = getFormattedDate(data[i].feeScheduleMeta.effectiveDate[0]);
        formattedData.expirationDate = getFormattedDate(data[i].feeScheduleMeta.effectiveDate[1]);

        newData.push(formattedData);
    }
    setSelectTableData(newData);
    setSelectedRowKey([]);
    setSelectedRecord({});
    setFeeSchduleDataSource([]);
  };

  const onPayorIdSearch = (value: string) => {
    console.log('search:', value);
  };
  
  const onGroupNoSearch = (value: string) => {
    console.log('search:', value);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: FeeScheduleSelectType[]) => {
      const data = allFeeSchedules.find((feeSchedule: FeeScheduleRecordType)=>
      feeSchedule.id===selectedRowKeys[0]);

      data.feeSchedules.map((obj: FeeScheduleType) => {
        if (!obj.key) {obj.key = obj.code};
        return obj;
      }) 

      setSelectedRowKey(selectedRowKeys);
      setSelectedRecord(data);
      setFeeSchduleDataSource(data.feeSchedules);
    }
  };

  useEffect(() => {
    if (!initialFeeScheduleId || initialFeeScheduleId ==='' || allFeeSchedules.length===0) {
      setPayorIdOptions([]);
      setGroupNoOptions([]);
      setSelectTableData([]);
      form.resetFields();
    } else {

      // need to popular form using inital selected fee schedule
      const selectedFeeScheduleRecord = allFeeSchedules.find((feeSchedule: FeeScheduleRecordType)=>
      feeSchedule.id===initialFeeScheduleId);

      if (!selectedFeeScheduleRecord) {
        return;
      }

      form.setFieldsValue(selectedFeeScheduleRecord.feeScheduleMeta);
      
      let data = allFeeSchedules.filter((feeSchedule: FeeScheduleRecordType)=>
      feeSchedule.feeScheduleMeta.insType===selectedFeeScheduleRecord.feeScheduleMeta.insType);
 
      setPayorIdOptions(data);

      data = data.filter((feeSchedule: FeeScheduleRecordType)=>
      feeSchedule.feeScheduleMeta.payorId===selectedFeeScheduleRecord.feeScheduleMeta.payorId);
  
      setGroupNoOptions(data);

      // set selection table data
      data = data.filter((feeSchedule: FeeScheduleRecordType)=>
      feeSchedule.feeScheduleMeta.insGroupNo===selectedFeeScheduleRecord.feeScheduleMeta.insGroupNo);
  
      // format data for table display
      const newData: FeeScheduleSelectType [] | any = [];
  
      for(let i=0; i<data.length; i++){
          const formattedData : FeeScheduleSelectType | any = {};
          formattedData.key = data[i].id;
          formattedData.comments = data[i].feeScheduleMeta.comments;
          formattedData.contributor = data[i].contributor;
          formattedData.contributorId = data[i].contributorId;
          formattedData.modifiedDate = getFormattedDate(data[i].modifiedDate);
          formattedData.insCarrier= data[i].feeScheduleMeta.insCarrier;
          formattedData.id = data[i].id;
          formattedData.insPlanName = data[i].feeScheduleMeta.insPlanName;
          formattedData.insGroupName = data[i].feeScheduleMeta.insGroupName;
          formattedData.effectiveDate = getFormattedDate(data[i].feeScheduleMeta.effectiveDate[0]);
          formattedData.expirationDate = getFormattedDate(data[i].feeScheduleMeta.effectiveDate[1]);
  
          newData.push(formattedData);
      }
      setSelectTableData(newData);

      // set selected row set intial value of selected row
      setSelectedRowKey([selectedFeeScheduleRecord.id]);

      // set main fee schedule table data
      data = allFeeSchedules.find((feeSchedule: FeeScheduleRecordType)=>
      feeSchedule.id===selectedFeeScheduleRecord.id);

      data.feeSchedules.map((obj: FeeScheduleType) => {
        if (!obj.key) {obj.key = obj.code};
        return obj;
      }) 

      setSelectedRecord(data);
      setFeeSchduleDataSource(data.feeSchedules);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [allFeeSchedules, initialFeeScheduleId]);

  return (
    <>
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        style={{ maxWidth: 600 }}
        form={form} 
      >
        <Form.Item label="Insurance Type" name='insType'>
          <Radio.Group onChange={onInsTypeChange}>
            {INSURANCE_TYPE.map(type => (
              <Radio key={type.id} value={type.id}>{type.type}</Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Select Payor ID" name='payorId'>
          <Select
            showSearch 
            placeholder="Select Payor ID"
            onChange={onPayorIdChange}
            onSearch={onPayorIdSearch}
          >
            {getPayorIdOptions()}
          </Select>
        </Form.Item>
        <Form.Item label="Select Group#" name='insGroupNo'>
          <Select
            showSearch 
            placeholder="Select Group#"
            onChange={onGroupNoChange}
            onSearch={onGroupNoSearch}
          >
            {getGroupOptions()}
          </Select>
        </Form.Item>
    </Form>
    { selectTableData.length>0 && 
      <Table 
        dataSource={selectTableData} 
        columns={columns} 
        rowSelection={{
          type: "radio",
          selectedRowKeys: selectedRowKey,
          ...rowSelection,
        }}
      />}
    </>
  );
};

export default DownloadSelect;
