import { useState, useEffect, createContext} from 'react';
import { googleLogout } from '@react-oauth/google';
import axios from 'axios';
import {message } from 'antd';

import LandingPage from "./landing/Landing";
import MainApp from "./MainApp";
import { UserProfile } from './types/userProfile';
import { MainAppContext } from './types/mainAppContext';

import { apiUrlBase } from './configs/constants';
import { CUSTOMER } from './configs/constants';

import { generateRandomUserName } from './util/util';

const defaultContext: MainAppContext = {
  logOut: () => console.log('sign out'),
  userProfile: undefined,
  user: undefined,
  logIn: () => console.log('sign in'),
  setUserProfile : () => console.log('sign in')
};

export const mainAppContext = createContext<MainAppContext>(defaultContext);

function App() {
  const [ user, setUser ] = useState< string | null>();
  const [ userProfile, setUserProfile ] = useState<UserProfile | undefined >();

  const logIn = async (userSignedIn: string) => {
    setUser(userSignedIn);
    localStorage.setItem("userSignedIn", String(userSignedIn));
  };
  
  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
      googleLogout();
      setUserProfile(undefined);
      setUser(null);
      localStorage.removeItem("userSignedIn");
  };

  useEffect(
    () => {

      const handleBeforeUnload = () => {
        // localStorage.removeItem('userSignedIn');
      }

      window.addEventListener('beforeunload', handleBeforeUnload);

      const userSignedIn = localStorage.getItem("userSignedIn");

      const fetchProfileData = async (token: string) => {
        let googleResponse = await axios.post('https://oauth2.googleapis.com/tokeninfo', { id_token: token });

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        };

        let apiUrl = `${apiUrlBase}/users/${googleResponse.data.email}`;
        let response = await axios.get(apiUrl, config);
        if (response.data) return response.data;

        const postData = {
          id: googleResponse.data.email,
          userType: CUSTOMER,
          fullName: googleResponse.data.name,
          email: googleResponse.data.email,
          screenId: `@${generateRandomUserName()}`,
          userImage: googleResponse.data.picture,
          userPhone: "",
          userAddress: ""
        };
        apiUrl = `${apiUrlBase}/users`;
        await axios.put(apiUrl, postData, config);
        return postData;
      };

      if (user) {
        // console.log('google token ' + user);

        fetchProfileData(user)
          .then((value) => {
            if (value.email.toLowerCase() === value.screenId.toLowerCase() ||
            value.screenId.charAt(0)==='@') {
              message.info('Please go to Settings and change your screen ID.');
            }
            setUserProfile(value);
          })
          .catch(console.error);

      } else {
        if (userSignedIn) {
          fetchProfileData(userSignedIn)
            .then((value) => {
              if (value.email.toUpperCase() === value.screenId.toUpperCase() ||
              value.screenId.charAt(0)==='@') {
                message.info('Please go to Settings and change your screen ID.');
              }
              setUserProfile(value);
              setUser(userSignedIn);
            })
            .catch(console.error);
        }
      };
    },
    [ user ]
  );

  return (
    <mainAppContext.Provider value={{userProfile, logOut, user, logIn, setUserProfile}}>
      {userProfile ? (
        <MainApp />
        ) : (
        <LandingPage />
      )}
    </mainAppContext.Provider>
  );
}

export default App;
