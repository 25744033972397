import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import HomeIcon from '@mui/icons-material/Home';

import FeeSchedulePageLayout from "../pages/feeschedule/FeeSchdulePageLayout";
import HomePage from "../pages/home/HomePage";
import { RouteType } from "./config";
import DownloadPage from "../pages/feeschedule/DownloadPage";
import FeeScheduleIndex from "../pages/feeschedule/FeeScheduleIndex";
import Settings from "../pages/settings/Settings";
import ProcessPage from "../pages/feeschedule/ProcessPage";
import AdminPageLayout from "../pages/admin/AdminPageLayout";
import UserManagementPage from "../pages/admin/UserManagementPage";
import BreakDownExisting from '../pages/breakdown/BreakDownExisting';
import BreakDownNew from '../pages/breakdown/BreakDownNew';
import BreakDownLayout from '../pages/breakdown/BreakDownLayout';
import BreakDownIndex from '../pages/breakdown/BreakDownIndex';
import { ADMIN, EMPLOYEE, CUSTOMER, PAID_CUSTOMER } from "../configs/constants";

const appRoutes: RouteType[] = [
  {
    index: true,
    element: <HomePage />,
    state: "home",
    access: [ADMIN,EMPLOYEE,CUSTOMER,PAID_CUSTOMER]
  },
  {
    path: "/",
    element: <HomePage />,
    state: "home",
    sidebarProps: {
      displayText: "Home",
      icon: <HomeIcon />
    },
    access: [ADMIN,EMPLOYEE,CUSTOMER,PAID_CUSTOMER]
  },
  {
    path: "/feeschedule",
    element: <FeeSchedulePageLayout />,
    state: "feeschedule",
    sidebarProps: {
      displayText: "Fee Schedule",
      icon: <DashboardOutlinedIcon />
    },
    access: [ADMIN,EMPLOYEE,CUSTOMER,PAID_CUSTOMER],
    child: [
      {
        index: true,
        element: <FeeScheduleIndex />,
        state: "feeschedule.index",
        access: [ADMIN,EMPLOYEE,CUSTOMER,PAID_CUSTOMER]
      },
      {
        path: "/feeschedule/existing",
        //element: <DownloadPage initialFeeScheduleId="acdb1a6e-23a4-48c0-b10b-55198b876b60"/>,
        element: <DownloadPage initialFeeScheduleId=''/>,
        state: "feeschedule.existing",
        sidebarProps: {
          displayText: "Existing"
        },
        access: [ADMIN,EMPLOYEE,CUSTOMER,PAID_CUSTOMER]
      },
      {
        path: "/feeschedule/new",
        element: <ProcessPage />,
        state: "feeschedule.new",
        sidebarProps: {
          displayText: "Create New"
        },
        access: [ADMIN,EMPLOYEE,CUSTOMER,PAID_CUSTOMER]
      }
    ]
  },
  {
    path: "/breakdown",
    element: <BreakDownLayout />,
    state: "breakdown",
    sidebarProps: {
      displayText: "Break Down",
      icon: <DashboardOutlinedIcon />
    },
    access: [ADMIN,EMPLOYEE,CUSTOMER,PAID_CUSTOMER],
    child: [
      {
        index: true,
        element: <BreakDownIndex />,
        state: "breakdown.index",
        access: [ADMIN,EMPLOYEE,CUSTOMER,PAID_CUSTOMER]
      },
      {
        path: "/breakdown/existing",
        //element: <DownloadPage initialFeeScheduleId="acdb1a6e-23a4-48c0-b10b-55198b876b60"/>,
        element: <BreakDownExisting />,
        state: "breakdown.existing",
        sidebarProps: {
          displayText: "Existing"
        },
        access: [ADMIN,EMPLOYEE,CUSTOMER,PAID_CUSTOMER]
      },
      {
        path: "/breakdown/new",
        element: <BreakDownNew />,
        state: "breakdown.new",
        sidebarProps: {
          displayText: "Create New"
        },
        access: [ADMIN,EMPLOYEE,CUSTOMER,PAID_CUSTOMER]
      }
    ]
  },
  {
    path: "/admin",
    element: <AdminPageLayout />,
    state: "component",
    sidebarProps: {
      displayText: "Admin",
      icon: <AdminPanelSettingsIcon />
    },
    access: [ADMIN],
    child: [
      {
        path: "/admin/users",
        element: <UserManagementPage />,
        state: "component.users",
        sidebarProps: {
          displayText: "Users"
        },
        access: [ADMIN,EMPLOYEE,CUSTOMER,PAID_CUSTOMER]
      }
    ]
  },
  {
    path: "/settings",
    element: <Settings />,
    state: "settings",
    sidebarProps: {
      displayText: "Settings",
      icon: <SettingsApplicationsIcon />
    },
    access: [ADMIN,EMPLOYEE,CUSTOMER,PAID_CUSTOMER]
  }
];

export default appRoutes;