import { useContext, useState} from 'react';
import { Form, Input, Button, Divider, Typography, Space, Modal, message, Alert} from 'antd';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from 'axios';

import { mainAppContext } from '../../App';
import { apiUrlBase } from '../../configs/constants';
import usePrompt from '../../components/layout/usePrompt';

const { Title } = Typography;
const { confirm } = Modal;

function Settings() {

  const {userProfile, user, setUserProfile} = useContext(mainAppContext);
  const [loading, setLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);

  const [form] = Form.useForm();

  const config = {
    headers: {
      Authorization: `Bearer ${user}`,
      'Content-Type': 'application/json',
    },
  };

  const handleFormChange = () => {
    setIsDirty(true);
  };

  const checkScreenIdUniqueness = async (screenId: string) => {
    const apiUrl = `${apiUrlBase}/users`;
    const configCheckScreenId = {
      headers: {
        Authorization: `Bearer ${user}`,
        'Content-Type': 'application/json',
      },
      params: {"screenId": screenId}
    };

    const response = await axios.get(apiUrl, configCheckScreenId);

    if (response.data.length >1) {return false};
    
    if (response.data.length === 0) {return true};

    if (response.data[0].id!==userProfile!.id) {return false};

    return true;
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    const isScreenIdUnique = await checkScreenIdUniqueness(values.screenId);

    if (isScreenIdUnique) {
      const postData = {
          id: userProfile?.id,   //cannot change
          userImage: userProfile?.userImage,   //cannot change
          userType: userProfile?.userType,  //cannot change
          fullName: values.fullName,
          screenId: values.screenId.toLowerCase(),
          email: values.email,
          userPhone: values.userPhone,
          userAddress: values.userAddress,
        };
        const apiUrl = `${apiUrlBase}/users/${userProfile!.id}`;
        await axios.put(apiUrl, postData, config);

        // need to update user profile
        setUserProfile(postData);
        setLoading(false);
        setIsDirty(false);
    } else {
      setErrorMessage('Screen ID must be unique. Please choose a different one.');
      setLoading(false);
    }
  }

  // Custom validation function for phone number
  const validatePhoneNumber = (rule: any, value: any, callback: any) => {
    if (!value) {
      callback();
    } else if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value)) {
      callback('Please enter a valid phone number!');
    } else {
      callback();
    }
  };
   
  const promptMessage = "Are you sure you want to leave? You have unsaved changes.";
  usePrompt(promptMessage, isDirty);

  const handleDelete = () => {
    confirm({
      title: "Are you sure you want to delete your account?",
      icon: <ExclamationCircleOutlined />,
      okType: "danger",
      async onOk() {
        const apiUrl = `${apiUrlBase}/users/${userProfile!.id}`;
        await axios.delete(apiUrl, config);
        message.success("Account deleted successfully");
        setUserProfile({});
        setIsDirty(false);
      },
    });
  };

  const handleInvalidScreenId = (event: any) => {
    event.preventDefault();
    message.error('Only numbers, lowercase charaters and special charater "[]!?" are allowed.');
  };
  
  return (
    <div style={{ padding: '0px' }}>
      <Title level={5}>Profile</Title>
      <Divider />
      {errorMessage && <Alert message={errorMessage} type="error" closable onClose={() => setErrorMessage(null)} />}
      <Form 
        form={form}
        onFinish={handleSubmit} 
        layout="horizontal"
        style={{ maxWidth: 600 }}
        initialValues={userProfile}
        onValuesChange={handleFormChange}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 15 }}
        size="middle"
        labelAlign='right'
      >
      <Form.Item label="Name" name="fullName" rules={[{ required: true, message: 'Please enter your name' }]}>
        <Input style={{ width: '50%' }} />
      </Form.Item>

      <Form.Item label="Screen ID" name="screenId" rules={[{ required: true, message: 'Please enter your screen ID' }]}>
        <Input style={{ width: '50%' }} maxLength={16} pattern="^[a-z0-9\[\]!\?]+$" onInvalid={handleInvalidScreenId}/>
      </Form.Item>

      <Form.Item label="Email" name="email" rules={[{ required: true, type: 'email', message: 'Please enter a valid email' }]}>
        <Input type="email" />
      </Form.Item>
      
      <Form.Item label="Phone"  name='userPhone' rules={[{validator: validatePhoneNumber}]}>
        <Input style={{ width: '50%' }} placeholder="888-888-8888" />
      </Form.Item>

      <Form.Item label="Address" name="userAddress" rules={[{ required: false, message: 'Please enter your address' }]}>
        <Input />
      </Form.Item>

      <Form.Item>
        <Space>
              <Button type="primary" ghost disabled={!isDirty} htmlType="submit" loading={loading}>
                Update Profile
              </Button>
              <Button type="primary" ghost danger onClick={handleDelete}>
                Delete Account
              </Button>
        </Space>
      </Form.Item>
    </Form>
    </div>
  );
}

export default Settings;
