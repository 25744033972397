import { useState, useEffect, useContext } from 'react';
import { Form, Select, Table, Row, Col, Button, Popconfirm, message} from 'antd';
import axios from 'axios';
import { v4 } from "uuid";

import { getFormattedDate } from '../../util/util';
import { BreakDownSelectType } from '../../types/breakDown';
import { mainAppContext } from '../../App';
import { apiUrlBase } from '../../configs/constants';

function BreakDownSelect (props: any)  {
  const {
    allBreakDowns,  // passed from parent
    iniSelectedInsCarrier,
    iniSelectedInsGroupNo,
    iniSelectedPatientName,
    parentSetSelectedInsCarrier,
    parentSetSelectedInsGroupNo,
    parentSetSelectedPatientName,
    setAllBreakDowns, // pass to parent
    parentHandleEdit, // pass to parent
  } = props;

  const {user} = useContext(mainAppContext);

  const config = {
    headers: {
      Authorization: `Bearer ${user}`,
      'Content-Type': 'application/json',
    },
  };

  const [selectTableData, setSelectTableData] = useState<BreakDownSelectType[]>([]);
  const [selectedInsCarrier, setSelectedInsCarrier] = useState<string> (iniSelectedInsCarrier);
  const [selectedInsGroupNo, setSelectedInsGroupNo] = useState<string> (iniSelectedInsGroupNo);
  const [selectedPatientName, setSelectedPatientName] = useState<string> (iniSelectedPatientName);

  const [form] = Form.useForm();

  let columns = [
    {
      title: 'Carrier',
      dataIndex: 'insCarrier',
      key: 'insCarrier',
    },
    {
      title: 'Group#',
      dataIndex: 'insGroupNo',
      key: 'insGroupNo',
    },
    {
      title: 'Effective Date',
      key: 'effectiveStart',
      dataIndex: 'effectiveStart',
    },
    {
        title: 'Patient Name',
        key: 'patientName',
        dataIndex: 'patientName',
      },
    {
      title: 'Patient DOB',
      key: 'patientDob',
      dataIndex: 'patientDob',
    },
    {
      title: 'Modified Date',
      key: 'modifiedDate',
      dataIndex: 'modifiedDate',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => {
        return (
          <span>
            <Button type="link" onClick={() => handleEdit(record.key)}>
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this row?"
              onConfirm={() => handleDelete(record.key)}
            >
              <Button type="link" danger>
                Delete
              </Button>
            </Popconfirm>
          </span>
        );
      },
    },
  ];


  const getCarrierOptions = () => {

    let uniqueData = allBreakDowns.map((breakDown: any) => breakDown.breakDowns.insuranceName)
        .filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index);
    uniqueData = uniqueData.filter((value: string) => value !== undefined );

    return uniqueData.map((item: any) => (
        <Select.Option key={v4()} value={item}>
            {item}
        </Select.Option>
    ));
  };

  const onCarrierChange = (value: string) => {

    const data = allBreakDowns.filter((breakDown: any)=>
    breakDown.breakDowns.insuranceName===value);

    // format data for table display
    const newData: BreakDownSelectType [] | any = [];

    for(let i=0; i<data.length; i++){
        const formattedData : BreakDownSelectType | any = {};
        formattedData.key = data[i].id;
        formattedData.patientName = data[i].breakDowns.patientName;
        formattedData.patientDob= data[i].breakDowns.patientDob;
        formattedData.modifiedDate = getFormattedDate(data[i].modifiedDate);
        formattedData.insCarrier= data[i].breakDowns.insuranceName;
        formattedData.id = data[i].id;
        formattedData.insGroupNo = data[i].breakDowns.insGroupNo;
        formattedData.effectiveStart = data[i].breakDowns.effectiveStart;

        newData.push(formattedData);
    }

    setSelectTableData(newData);
    setSelectedInsCarrier(value);
    setSelectedPatientName('Select Patient');
    setSelectedInsGroupNo('Select Group#');

    form.setFieldValue('patientName', 'Select Patient');
    form.setFieldValue('groupNo', 'Select Group#');
  };
  
  const getGroupNoOptions = () => {

    let uniqueData = allBreakDowns.map((breakDown: any) => breakDown.breakDowns.insGroupNo)
        .filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index);

    uniqueData = uniqueData.filter((value: string) => value !== undefined );

    return uniqueData.map((item: any) => (
        <Select.Option key={v4()} value={item}>
            {item}
        </Select.Option>
    ));
  };

  const onGroupNoChange = (value: string) => {

    const data = allBreakDowns.filter((breakDown: any)=>
    breakDown.breakDowns.insGroupNo===value);

    // format data for table display
    const newData: BreakDownSelectType [] | any = [];

    for(let i=0; i<data.length; i++){
        const formattedData : BreakDownSelectType | any = {};
        formattedData.key = data[i].id;
        formattedData.patientName = data[i].breakDowns.patientName;
        formattedData.patientDob= data[i].breakDowns.patientDob;
        formattedData.modifiedDate = getFormattedDate(data[i].modifiedDate);
        formattedData.insCarrier= data[i].breakDowns.insuranceName;
        formattedData.id = data[i].id;
        formattedData.insGroupNo = data[i].breakDowns.insGroupNo;
        formattedData.effectiveStart = data[i].breakDowns.effectiveStart;

        newData.push(formattedData);
    }

    setSelectTableData(newData);
    setSelectedInsGroupNo(value);
    setSelectedPatientName('Select Patient');
    setSelectedInsCarrier('Select Insurance');

    form.setFieldValue('patientName', 'Select Patient');
    form.setFieldValue('carrierId', 'Select Insurance');
  };

  const getPatientOptions = () => {
    let uniqueData = allBreakDowns.map((breakDown: any) => breakDown.breakDowns.patientName)
        .filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index);
    uniqueData = uniqueData.filter((value: string) => value !== undefined );

    return uniqueData.map((item: any) => (
        <Select.Option key={v4()} value={item}>
            {item}
        </Select.Option>
    ));
  };

  const onPatientChange = (value: string) => {
    const data = allBreakDowns.filter((breakDown: any)=>
    breakDown.breakDowns.patientName===value);

    // format data for table display
    const newData: BreakDownSelectType [] | any = [];

    for(let i=0; i<data.length; i++){
        const formattedData : BreakDownSelectType | any = {};
        formattedData.key = data[i].id;
        formattedData.patientName = data[i].breakDowns.patientName;
        formattedData.patientDob= data[i].breakDowns.patientDob;
        formattedData.modifiedDate = getFormattedDate(data[i].modifiedDate);
        formattedData.insCarrier= data[i].breakDowns.insuranceName;
        formattedData.id = data[i].id;
        formattedData.insGroupNo = data[i].breakDowns.insGroupNo;
        formattedData.effectiveStart = data[i].breakDowns.effectiveStart;

        newData.push(formattedData);
    }

    setSelectTableData(newData);
    setSelectedInsCarrier('Select Insurance');
    setSelectedPatientName(value);
    setSelectedInsGroupNo('Select Group#');

    form.setFieldValue('carrierId', 'Select Insurance');
    form.setFieldValue('groupNo', 'Select Group#');
  };

  const onCarrierSearch = (value: string) => {
    console.log('search:', value);
  };
  
  const onGroupNoSearch = (value: string) => {
    console.log('search:', value);
  };

  const onPatientSearch = (value: string) => {
    console.log('search:', value);
  };

  const handleDelete = async (key: string) => {
    const newData = [...allBreakDowns];
    const index = newData.findIndex((item) => key === item.id);
    newData.splice(index, 1);

    const apiUrl = `${apiUrlBase}/breakdowns/${key}`;
    try {
      await axios.delete(apiUrl, config);
      setAllBreakDowns(newData);
    } catch (error) {
      console.log(error)
      message.error('error occured while deleting');
    }
  };

  const handleEdit = (key: string) => {
    parentSetSelectedInsCarrier(selectedInsCarrier);
    parentSetSelectedInsGroupNo(selectedInsGroupNo);
    parentSetSelectedPatientName(selectedPatientName);
    parentHandleEdit(key);
  };

  useEffect(() => {
    let data;

    if (selectedInsCarrier!=='Select Insurance' && selectedInsCarrier!=='') {
      data = allBreakDowns.filter((breakDown: any)=>
      breakDown.breakDowns.insuranceName===selectedInsCarrier);
      form.setFieldValue('carrierId', selectedInsCarrier);
    } else if (selectedInsGroupNo!=='Select Group#' && selectedInsGroupNo!=='') {
      data = allBreakDowns.filter((breakDown: any)=>
      breakDown.breakDowns.insGroupNo===selectedInsGroupNo);
      form.setFieldValue('groupNo', selectedInsGroupNo);
    } else if(selectedPatientName!=='Select Patient' && selectedPatientName!=='') {
      data = allBreakDowns.filter((breakDown: any)=>
      breakDown.breakDowns.patientName===selectedPatientName);
      form.setFieldValue('patientName', selectedPatientName);
    } else {
      setSelectTableData([]);
      return;
    }

    // format data for table display
    const newData: BreakDownSelectType [] | any = [];
  
    for(let i=0; i<data.length; i++){
      const formattedData : BreakDownSelectType | any = {};
      formattedData.key = data[i].id;
      formattedData.patientName = data[i].breakDowns.patientName;
      formattedData.patientDob= data[i].breakDowns.patientDob;
      formattedData.modifiedDate = getFormattedDate(data[i].modifiedDate);
      formattedData.insCarrier= data[i].breakDowns.insuranceName;
      formattedData.id = data[i].id;
      formattedData.insGroupNo = data[i].breakDowns.insGroupNo;
      formattedData.effectiveStart = data[i].breakDowns.effectiveStart;
  
      newData.push(formattedData);
    }
  
    setSelectTableData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [allBreakDowns]);

  return (
    <>
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        style={{ maxWidth: 600 }}
        form={form} 
      >
       <Row gutter={16}>
          <Col span={8}>
            <Form.Item name='carrierId'>
              <Select
                style={{ minWidth: 200 }}
                showSearch 
                placeholder="Select Insurance"
                onChange={onCarrierChange}
                onSearch={onCarrierSearch}
              >
                {getCarrierOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name='groupNo'>
              <Select
                style={{ minWidth: 200 }}
                showSearch 
                placeholder="Select Group#"
                onChange={onGroupNoChange}
                onSearch={onGroupNoSearch}
              >
                {getGroupNoOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name='patientName'>
              <Select
                style={{ minWidth: 200 }}
                showSearch 
                placeholder="Select Patient"
                onChange={onPatientChange}
                onSearch={onPatientSearch}
              >
                {getPatientOptions()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
    </Form>
    { selectTableData.length>0 && 
      <Table 
        dataSource={selectTableData} 
        columns={columns} 
      />}
    </>
  );
};

export default BreakDownSelect;
