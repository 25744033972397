import { Typography } from 'antd';

const { Text, Paragraph} = Typography;

function UploadInstruction ()  {
  const url = 'https://ocr.space/';

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    window.open(url, '_blank');
  };
 return (
    <div>
        <Paragraph>
          <Text strong>Instruction</Text>
        </Paragraph>
        <Paragraph>
        To begin, either add your codes manually or upload a CSV file. The CSV file should contain the following columns:
        </Paragraph>
        <Paragraph>
        <Text code style={{ width: "80%" }} >
          category,description,abbr,code,fee1,fee2,fee3 
        </Text>
        </Paragraph>
        <Paragraph>
          Note that the column names are case-sensitive. The <b>description</b>,  <b>code</b> and <b>fee1</b> columns are required, while the rest are optional.
        </Paragraph>
        <Paragraph>
          After adding your data, be sure to double-check it and make any necessary edits. Once you're satisfied with the data, fill in all of the necessary metadata for the fee schedule and click the "Save" button.
        </Paragraph> 
        <Paragraph>
          If you need to extract data from a PDF file, you can use the free online tool <a href={url} onClick={handleLinkClick}>OCR Space.</a> After extracting the data, compile it into a CSV file and then upload it to the system.
        </Paragraph>
      </div>    
 );
};

export default UploadInstruction;