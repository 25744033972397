import React from 'react'

import Testimonials from '../misc/Testimonials'
import LandingHeader from '../misc/LandingHeader'

const Home = () => {
    return (
        <React.Fragment>
            <LandingHeader />
            <Testimonials />
        </React.Fragment>
    )
}

export default Home;
