import React, {useState, FormEvent} from 'react'
import { apiUrlBase } from '../../../configs/constants';

const apiUrl = `${apiUrlBase}/send-email`;

const ContactUs = () => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = { name, email, message, subject, phone };
    try {
      await fetch(apiUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      alert("Thank you for your message! We will get back to you soon.");
    } catch (error) {
      alert('Something wrong has happened. Message not sent!');
    }
  };

  return (
    <React.Fragment>
      <section className="content-container">
        <div className="textArea"> 
          <p>
            We would love to hear from you!<br/>
            How can we help you?
          </p>
        </div>
        <div className="block">
          <div className="row">
            <div className="col-left">
              <form id="contact" onSubmit={handleSubmit}>
                <fieldset>
                  <label 
                    style={{ display: "inline-block", width: "55px", textAlign: "left", marginRight: "10px" }}
                    htmlFor='name'
                  >
                    Name:
                  </label>
                  <input 
                    style={{ display: "inline-block", width: "345px"}}
                    placeholder="Your Name"
                    type="text" 
                    tabIndex={1} 
                    required 
                    autoFocus 
                    id="name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </fieldset>
                <fieldset>
                <label 
                    style={{ display: "inline-block", width: "55px", textAlign: "left", marginRight: "10px" }}
                    htmlFor='subject'
                  >
                    Subject:
                  </label>
                  <input 
                    style={{ display: "inline-block", width: "345px"}}
                    placeholder="Subject" 
                    type="text" 
                    tabIndex={2} 
                    required 
                    autoFocus
                    id="subject"
                    value={subject}
                    onChange={(event) => setSubject(event.target.value)}
                  />
                </fieldset>
                <fieldset>
                <label 
                    style={{ display: "inline-block", width: "55px", textAlign: "left", marginRight: "10px" }}
                    htmlFor='email'
                  >
                    Email:
                  </label>
                  <input 
                    style={{ display: "inline-block", width: "345px"}}
                    placeholder="Your Email Address" 
                    type="email" 
                    tabIndex={3} 
                    required 
                    id="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </fieldset>
                <fieldset>
                  <label 
                    style={{ display: "inline-block", width: "55px", textAlign: "left", marginRight: "10px" }}
                    htmlFor='phone'
                  >
                    Phone:
                  </label>
                  <input
                    style={{ display: "inline-block", width: "345px"}} 
                    placeholder="Your Phone Number" 
                    type="tel" 
                    tabIndex={4} 
                    id="phone"
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)} 
                  />
                </fieldset>
                <fieldset>
                  <textarea 
                    style={{ width: "400px"}} 
                    placeholder="Type your Message Here...." 
                    tabIndex={5} 
                    required
                    id="message"
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                  />
                </fieldset>
                <fieldset>
                  <button name="submit" type="submit" id="contact-submit" data-submit="...Sending">Submit</button>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default ContactUs;