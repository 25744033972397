export const apiUrlBase =  process.env.REACT_APP_FEESCHEDULE_BASEURL||'https://api.serenityfamilydental.us'; 
// export const apiUrlBase = 'https://a3zbtq0o1f.execute-api.us-east-1.amazonaws.com'; 

export const ADMIN = 1;
export const EMPLOYEE = 2;
export const CUSTOMER = 3;
export const PAID_CUSTOMER = 4;

export const userTypes = [{
  "id": ADMIN,
  "type": "Admin"
  }, {
      "id": EMPLOYEE,
      "type": "Employee"
  }, {
      "id": CUSTOMER,
      "type": "Customer"
  }, {
      "id": PAID_CUSTOMER,
      "type": "Paid Customer"
  }];


export const MEDICAL = 1;
export const DENTAL= 2;
export const VISION= 3;

export const INSURANCE_TYPE = [{
  "id": MEDICAL,
  "type": "Medical"
  }, {
      "id": DENTAL,
      "type": "Dental"
  }, {
      "id": VISION,
      "type": "Vision"
  }];

export const TEST_FEE_SCHEDULE_DATA = [
    {
      category: 'preventative',
      description: 'cleaning',
      abbr: 'cle',
      code: 'D101',
      fee1: 123.01,
    },
    {
      category: 'preventative',
      description: 'cleaning',
      abbr: 'cle0',
      code: 'D102',
      fee1: 456.87,
    },
    {
      category: 'preventative',
      description: 'cleaning',
      abbr: 'cle1',
      code: 'D103',
      fee1: 12.01,
    },
    {
      category: 'preventative',
      description: 'cleaning',
      abbr: 'cle1',
      code: 'D104',
      fee1: 12.01,
    },
    {
      category: 'preventative',
      description: 'cleaning',
      abbr: 'cle1',
      code: 'D105',
      fee1: 12.01,
    },
    {
      category: 'preventative',
      description: 'cleaning',
      abbr: 'cle1',
      code: 'D106',
      fee1: 12.01,
    },
    {
      category: 'preventative',
      description: 'cleaning',
      abbr: 'cle1',
      code: 'D107',
      fee1: 12.01,
    },
    {
      category: 'preventative',
      description: 'cleaning',
      abbr: 'cle1',
      code: 'D108',
      fee1: 12.01,
    },
    {
      category: 'preventative',
      description: 'cleaning',
      abbr: 'cle1',
      code: 'D109',
      fee1: 12.01,
    },
    {
      category: 'preventative',
      description: 'cleaning',
      abbr: 'cle1',
      code: 'D110',
      fee1: 12.01,
    },
    {
      category: 'preventative',
      description: 'cleaning',
      abbr: 'cle1',
      code: 'D111',
      fee1: 12.01,
    },
    {
      category: 'preventative',
      description: 'cleaning',
      abbr: 'cle1',
      code: 'D112',
      fee1: 12.01,
    },
    {
      category: 'preventative',
      description: 'cleaning',
      abbr: 'cle1',
      code: 'D113',
      fee1: 12.01,
    },
  ];
