import { useState, useEffect, useContext } from 'react';

import { Typography, Divider, message, Button, Modal} from 'antd';
import { ExclamationCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import axios from 'axios';

import BreakDownSelect from './BreakDownSelect';
import BreakDownForm from './BreakDownForm';
import { mainAppContext } from '../../App';
import { apiUrlBase } from '../../configs/constants';
import { BreakDownRecordType} from '../../types/breakDown';

function BreakDownExisting () {

  const {user} = useContext(mainAppContext);

  const [allBreakDowns, setAllBreakDowns] = useState<BreakDownRecordType[]> ([]);
  const [editMode, setEditMode] = useState(false);
  const [selectedInsCarrier, setSelectedInsCarrier] = useState<string> ('');
  const [selectedInsGroupNo, setSelectedInsGroupNo] = useState<string> ('');
  const [selectedPatientName, setSelectedPatientName] = useState<string> ('');
  const [selectedBreakDown, setSelectedBreakDown] = useState<BreakDownRecordType> ();
  const [isDirty, setIsDirty] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${user}`,
      'Content-Type': 'application/json',
    },
  };

  const refresh = async () => {
    let apiUrl = `${apiUrlBase}/breakdowns`;
    try {
      const response = await axios.get(apiUrl, config);
      setAllBreakDowns(response.data);
    } catch (error) {
      console.error(error); 
      message.error('cannot download data.');
    }

  };

  useEffect(() => {
    // Load all fee schedules from the database via an axios call and only once

    let apiUrl = `${apiUrlBase}/breakdowns`;
  
    axios.get(apiUrl, config)
      .then(response => {
        setAllBreakDowns(response.data);
      })
      .catch(error => {
        console.error(error); 
        message.error('cannot download data.');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleGoBack = () => {
    if (isDirty) {
      Modal.confirm({
        title: "You have unsaved changes. Are you sure you want to leave?",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          setEditMode(false);
         },
      });
    } else {
      setEditMode(false);
    }
  };

  const handleEdit = (key: string) => {
    setSelectedBreakDown(allBreakDowns.find((item) => item.id === key));
    setEditMode(true);
  };

  return (
    <>
    { !editMode && <Typography.Title level={5}>Search Break Down Sheet</Typography.Title>}
    { editMode && 
      <Button type="link" size="small" icon={<ArrowLeftOutlined />} onClick={() => handleGoBack()} >
        Go Back  
      </Button>}
    { !editMode && <Divider /> }
    { !editMode && <BreakDownSelect 
      allBreakDowns={allBreakDowns}
      setAllBreakDowns={setAllBreakDowns}
      parentHandleEdit={handleEdit}
      iniSelectedInsCarrier={selectedInsCarrier}
      iniSelectedInsGroupNo={selectedInsGroupNo}
      iniSelectedPatientName={selectedPatientName}
      parentSetSelectedInsCarrier={setSelectedInsCarrier}
      parentSetSelectedInsGroupNo={setSelectedInsGroupNo}
      parentSetSelectedPatientName={setSelectedPatientName}
    />}
    { editMode && 
    <BreakDownForm 
      breakDown={selectedBreakDown}
      setParentIsDirty={setIsDirty}
      refreshParent={refresh}
    >
    </BreakDownForm >}
    </>
  );
}

export default BreakDownExisting;
