import React from 'react'
import './LandingHeader.css'

const LandingHeader = () => {
    return (
        <React.Fragment>
            <header className="bg-image">
                <div className="bg-container">
                    <h1>Hotpot of Technical Innovations</h1>
                    <h2>Tools That Help Your Business</h2>
                </div>
            </header>
        </React.Fragment>
    )
}

export default LandingHeader;