export type FeeScheduleType = {
    key?: string;
    category?: string;
    description: string;
    abbr?: string;
    code: string;
    fee1: number;
    fee2?: number;
    fee3?: number;
  };

export type FeeScheduleMetaType = {
  insType: string;
  comments?: string;
  payorId: string;
  insCarrier: string;
  insPlanName: string;
  insGroupNo: string;
  insGroupName: string;
  effectiveDate: Date[];
};

export type FeeSchduleScoresType = {
  voterid: string;
  score: number;
  scoreDate: Date;
};

export type FeeScheduleRecordType = {
  id: string,
  feeSchedules: FeeScheduleType[];
  feeScheduleMeta: FeeScheduleMetaType;
  contributor: string;
  uploadDate: Date;
  modifiedDate: Date;
  scores?: FeeSchduleScoresType[];
  contributorId: string;
};

// specifically for selection table
export type FeeScheduleSelectType = {
  id: string,
  comments?: string;
  insCarrier: string;
  insPlanName: string;
  insGroupName: string;
  effectiveDate: string;
  expirationDate: string;
  contributor: string;
  contributorId: string;
  modifiedDate: string;
};

// Define a function to check if an object is a FeeScheduleMetaType
export function isFeeScheduleMetaType(obj: any): obj is FeeScheduleMetaType {
  return (
    typeof obj.insType === 'string' &&
    typeof obj.payorId === 'string' &&
    typeof obj.insCarrier === 'string' &&
    typeof obj.insPlanName === 'string' &&
    typeof obj.insGroupNo === 'string' &&
    typeof obj.insGroupName === 'string' &&
    obj.effectiveDate[0] instanceof Date &&
    obj.effectiveDate[1] instanceof Date 
  );
}

// Define a function to check if an object is a FeeScheduleType
export function areAllFeeScheduleType(arr: any[]): arr is FeeScheduleType[] {
  return arr.every((obj) =>
  typeof obj.description === "string" &&
  typeof obj.code === "string" &&
  typeof obj.fee1 === "number"
  );
}

// Define a function to check if an object is a FeeScheduleType
export function isFeeScheduleType(obj: any): obj is FeeScheduleType {
  return (
    typeof obj.description === "string" &&
    typeof obj.code === "string" &&
    typeof obj.fee1 === "number"
  );
}
