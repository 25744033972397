import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';

import './MenuBar.css'

import { mainAppContext } from '../../../App';

const MenuBar = () => {
    const navigate = useNavigate();
    const {logIn} = useContext(mainAppContext);
    return (
        <nav className="header">
            <div className="nav-wrapper">
                <a className="logo" href='/'>Your Logo</a>
                <input className="menu-btn" type="checkbox" id="menu-btn"/>
                <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>

                <ul className="menu">
                    <li><a href="/">Home</a></li>
                    <li><a href="/AboutUs">About Us</a></li>
                    <li><a href="/ContactUs">Contact Us</a></li>
                    <li className="google-login">
                        <GoogleLogin
                            onSuccess={credentialResponse => {
                                logIn(credentialResponse.credential!);
                                navigate("/");
                            }}
                            size="medium"
                            shape="pill"
                            onError={() => {
                                console.log('Login Failed');
                            }}
                        />
                    </li>                
                </ul>
            </div>
        </nav>
    )
}

export default MenuBar;