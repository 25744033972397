import { useContext, useEffect, useState} from 'react';
import { Button, Popconfirm, Table, Typography, 
  Divider, Form, Space, message, Modal} from 'antd';
import * as Papa from 'papaparse';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { ExclamationCircleOutlined, DownloadOutlined, SaveOutlined, 
  DeleteOutlined, FolderAddOutlined } from "@ant-design/icons";

import { mainAppContext } from '../../App';
import { FeeScheduleRecordType, FeeScheduleType } from '../../types/feeSchedule';
import usePrompt from '../../components/layout/usePrompt';

import { apiUrlBase } from '../../configs/constants';
import { ADMIN, EMPLOYEE } from '../../configs/constants';
import FeeScheduleEditModal from "./FeeScheduleEditModal";
import FeeScheduleSaveAsModal from './FeeScheduleSaveAsModal';
import DownloadSelect from './DownloadSelect';
import { isNumeric, getFormattedDate } from '../../util/util';

function DownloadPage(props: any) {
  const {user, userProfile} = useContext(mainAppContext);
  const {initialFeeScheduleId} = props;
  
  const access = [ADMIN, EMPLOYEE].includes(userProfile!.userType);

  const [selectedRecord, setSelectedRecord] = useState<FeeScheduleRecordType> ();
  const [dataSource, setDataSource] = useState<FeeScheduleType []>([]);
  const [editingKey, setEditingKey] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showSaveAsModal, setShowSaveAsModal] = useState(false);
  const [codeEditEnable, setCodeEditEnable] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingSaveAs, setLoadingSaveAs] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [allFeeSchedules, setAllFeeSchedules] = useState<FeeScheduleRecordType []>([]);
  const [initialFeeScheduleIdChild, setInitialFeeScheduleIdChild] = useState(initialFeeScheduleId);

  const config = {
    headers: {
      Authorization: `Bearer ${user}`,
      'Content-Type': 'application/json',
    },
  };

  useEffect(() => {
    // Load all fee schedules from the database via an axios call and only once

    let apiUrl = `${apiUrlBase}/feeschedules`;
  
    axios.get(apiUrl, config)
      .then(response => {
        setAllFeeSchedules(response.data);
      })
      .catch(error => {
        console.error(error); 
        message.error('cannot download data.');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  const handleDelete = (key: string) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    newData.splice(index, 1);
    setDataSource(newData);
    setIsDirty(true)
  };

  const handleEdit = (key: string) => {
    form.resetFields();
    setEditingKey(key);
    setCodeEditEnable(false);
    setShowModal(true);
    form.setFieldsValue(dataSource.find((item) => item.key === key));
  };

  const handleCancel = () => {
    setEditingKey('');
    setShowModal(false);
  };

  const handleSaveAsModalCancel = () => {
    setShowSaveAsModal(false);
  };

  const handleSave = () => {
    const newData = [...dataSource];
    const key = editingKey;

    if (key && key !== '') {
      const index = newData.findIndex((item) => key === item.key);
      const item = newData[index];
      const updatedItem = { ...item, ...form.getFieldsValue() };
      newData.splice(index, 1, updatedItem);
    } else {
      // if the code was there, we update the code instead of add
      const index = newData.findIndex((item) => form.getFieldValue('code') === item.code);
      if (index !== -1) {
        const item = newData[index];
        const updatedItem = { ...item, ...form.getFieldsValue() };
        newData.splice(index, 1, updatedItem);
      } else {
        const newItem = form.getFieldsValue();
        newItem.key = form.getFieldValue('code');
        newData.push(newItem);
      }
    }

    setDataSource(newData);
    setEditingKey('');
    setShowModal(false);
  };

  const [form] = Form.useForm();
  const [saveAsForm] = Form.useForm();

  let columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Abbr',
      dataIndex: 'abbr',
      key: 'abbr',
    },
    {
      title: 'Code',
      key: 'code',
      dataIndex: 'code',
    },
    {
      title: 'Fee 1',
      key: 'fee1',
      dataIndex: 'fee1',
    },
    {
      title: 'Fee 2',
      key: 'fee2',
      dataIndex: 'fee2',
    },
    {
      title: 'Fee 3',
      key: 'fee3',
      dataIndex: 'fee3',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => {
        return (
          <span>
            <Button type="link" onClick={() => handleEdit(record.key)}>
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this row?"
              onConfirm={() => handleDelete(record.key)}
            >
              <Button type="link" danger>
                Delete
              </Button>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  /*
  if(!access) {
    columns = columns.filter(
      (column) => column.key !== "action"
    );
  }
  */

  const handleFormChange = () => {
    setIsDirty(true);
  };

  // if insType, payorId, groupNo, contributor, effective date not changed,
  // then update existing record, otherwise create a new one
  // because here user cannot change meta data, some only check if contributor
  // is different or not
  const handleFormSave = async () => {
    //validate data first
    for (var i = 0; i < dataSource.length; i++) {
      if (!dataSource[i].description || dataSource[i].description ==='') {
        message.error(`Please enter fee schedule description in table row ${i+1}`);
        return false;
      }
      if (!dataSource[i].code || dataSource[i].code ==='') {
        message.error(`Please enter fee schedule code in table row ${i+1}`);
        return false;
      }
      if (!dataSource[i].fee1 || !isNumeric(dataSource[i].fee1)) {
        message.error(`Please enter valid fee 1 in table row ${i+1}`);
        return false;
      }
      if (dataSource[i].fee2 && !isNumeric(dataSource[i].fee2)) {
        message.error(`Please enter fee schedule fee 2 in table row ${i+1}`);
        return false;
      }
      if (dataSource[i].fee3 && !isNumeric(dataSource[i].fee3)) {
        message.error(`Please enter fee schedule fee 3 in table row ${i+1}`);
        return false;
      }
    }

    const dataSourceCopy = dataSource.filter(function (data) {
      delete data.key;
      return true;
    });

    setLoadingSave(true);

    // save to database
    const postData = {
      feeSchedules: dataSourceCopy,   
      feeScheduleMeta: selectedRecord!.feeScheduleMeta,  
      contributor: userProfile!.screenId,
      contributorId: userProfile!.id,
    };

    try {
      let apiUrl = `${apiUrlBase}/feeschedules`;

      if (selectedRecord!.contributorId === userProfile!.id) {
        apiUrl =`${apiUrlBase}/feeschedules/${selectedRecord!.id}`;
      }
      let response = await axios.put(apiUrl, postData, config);
      const updatedId = response.data.split(" ").pop();

      // read back updated fee schedule so that we have time stamps
      apiUrl = `${apiUrlBase}/feeschedules`;
  
      response = await axios.get(apiUrl, config);
  
      setAllFeeSchedules(response.data);
      setInitialFeeScheduleIdChild(updatedId);
      setIsDirty(false);
      setLoadingSave(false);

    } catch (error) {
      console.log(error)
      message.error('error occured while saving');
      setLoadingSave(false);
    }
  };

  const handleDownload = () => {
    setLoading(true);
    // Convert data to CSV format
    const dataSourceCopy = dataSource.filter(function (data) {
      delete data.key;
      return true;
    });

    const csv = Papa.unparse(dataSourceCopy);

    // Save CSV file using file-saver library
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'data.csv');
    setLoading(false);
  };

  const handleAdd = () => {
    setEditingKey('');
    setCodeEditEnable(true);
    form.resetFields();
    setShowModal(true);
  };

  const handleSaveAs = () => {
    const initialMetaData: any = {...selectedRecord!.feeScheduleMeta};
    delete initialMetaData.effectiveDate; 
    delete initialMetaData.comments;
    // set inital value for reference
    saveAsForm.setFieldsValue(initialMetaData);
    setShowSaveAsModal(true);
  };

  const handleSaveAsModalOk = async () => {

    const metaValue = saveAsForm.getFieldsValue();

    //do thorough check
    if (!metaValue.insType) {
      message.error('Please choose insurance type.');
      return false;
    }

    if (!metaValue.payorId) {
      message.error('Please enter payor ID.');
      return false;
    }

    if (!metaValue.payorId) {
      message.error('Please enter payor ID.');
      return false;
    }

    if (!metaValue.insCarrier) {
      message.error('Please enter insurance carrier.');
      return false;
    }
    
    if (!metaValue.insPlanName) {
      message.error('Please enter insurance plan name.');
      return false;
    }

    if (!metaValue.insGroupNo) {
      message.error('Please enter insurance group #.');
      return false;
    }

    if (!metaValue.insGroupName) {
      message.error('Please enter insurance group name.');
      return false;
    }

    if (!metaValue.effectiveDate) {
      message.error('Please enter insurance effective date.');
      return false;
    }

    const initialMetaData = {...selectedRecord!.feeScheduleMeta};

    if ( initialMetaData.insType === metaValue.insType &&
         initialMetaData.payorId === metaValue.payorId &&
         initialMetaData.insCarrier === metaValue.insCarrier &&
         initialMetaData.insPlanName === metaValue.insPlanName &&
         initialMetaData.insGroupNo === metaValue.insGroupNo &&
         initialMetaData.insGroupName === metaValue.insGroupName &&
         getFormattedDate(initialMetaData.effectiveDate[0])===getFormattedDate(metaValue.effectiveDate[0]) &&
         getFormattedDate(initialMetaData.effectiveDate[1])===getFormattedDate(metaValue.effectiveDate[1])) {
      message.info('No meta data is changed. Please use the Save Button instead.');
      return false;
    }

    if (dataSource.length <1) {
      message.error('There is no fee schedule code.');
      return false;
    }

    for (var i = 0; i < dataSource.length; i++) {
      if (!dataSource[i].description || dataSource[i].description ==='') {
        message.error(`Please enter fee schedule description in table row ${i+1}`);
        return false;
      }
      if (!dataSource[i].code || dataSource[i].code ==='') {
        message.error(`Please enter fee schedule code in table row ${i+1}`);
        return false;
      }
      if (!dataSource[i].fee1 || !isNumeric(dataSource[i].fee1)) {
        message.error(`Please enter valid fee 1 in table row ${i+1}`);
        return false;
      }
      if (dataSource[i].fee2 && !isNumeric(dataSource[i].fee2)) {
        message.error(`Please enter fee schedule fee 2 in table row ${i+1}`);
        return false;
      }
      if (dataSource[i].fee3 && !isNumeric(dataSource[i].fee3)) {
        message.error(`Please enter fee schedule fee 3 in table row ${i+1}`);
        return false;
      }
    }

    const dataSourceCopy = dataSource.filter(function (data) {
      delete data.key;
      return true;
    });

    // save to database
    const postData = {
      feeSchedules: dataSourceCopy,   
      feeScheduleMeta: metaValue,  
      contributor: userProfile!.screenId,
      contributorId: userProfile!.id,
    };

    setLoadingSaveAs(true);

    try {
      const apiUrl = `${apiUrlBase}/feeschedules`;

      let response = await axios.put(apiUrl, postData, config);
      const updatedId = response.data.split(" ").pop();

      // read back updated fee schedule so that we have time stamps 
      response = await axios.get(apiUrl, config);
  
      setAllFeeSchedules(response.data);
      setInitialFeeScheduleIdChild(updatedId);
      
    } catch (error) {
      console.log(error)
      message.error('error occured while saving');
    }

    setIsDirty(false);
    setLoadingSaveAs(false);
    setShowSaveAsModal(false);
  };

  const handleFormDelete = () => {
    Modal.confirm({
      title: "Are you sure you want to delete this fee schedule?",
      icon: <ExclamationCircleOutlined />,
      okType: "danger",
      async onOk() {
        try {
          setLoadingDelete(true);
          const apiUrl = `${apiUrlBase}/feeschedules/${selectedRecord!.id}`;
          await axios.delete(apiUrl, config);
          setIsDirty(false);
          setLoadingDelete(false);
          setDataSource([]);
          const newData = allFeeSchedules.filter(
            (data) => data.id !== selectedRecord!.id)
          setAllFeeSchedules(newData);
          message.success("Fee Schedule is deleted successfully");
        } catch (error) {
          console.log(error);
          message.error('cannot delete this fee schedule');
          setLoadingDelete(false);
        }
      },
    });
  }

  const promptMessage = "Are you sure you want to leave? You have unsaved changes.";
  usePrompt(promptMessage, isDirty);

  return (
    <>
      <Typography.Title level={5}>Download or Edit Fee Schedule</Typography.Title>
      <Divider />
      <DownloadSelect 
        allFeeSchedules={allFeeSchedules} 
        setSelectedRecord={setSelectedRecord}
        setFeeSchduleDataSource={setDataSource}
        initialFeeScheduleId={initialFeeScheduleIdChild}
      />
      { dataSource.length>0 && <Table dataSource={dataSource} columns={columns} />}
      <Space>
        { dataSource.length>0 && 
        <Button type="primary" ghost loading={loading} onClick={handleDownload}  icon={<DownloadOutlined />}>
          Download
        </Button>}
        { selectedRecord && dataSource.length>0 &&
        <Button type="primary" ghost onClick={handleAdd} icon={<FolderAddOutlined />}>
          Add a Code
        </Button>
        }
        { selectedRecord && dataSource.length>0 &&
        <Button type="primary" ghost disabled={!isDirty} loading={loadingSave} onClick={handleFormSave} icon={<SaveOutlined />}>
          Save
        </Button>
        }
        { selectedRecord && dataSource.length>0 &&
        <Button type="primary" ghost onClick={handleSaveAs} loading={loadingSaveAs} icon={<SaveOutlined />}>
          Save As
        </Button>
        }
        { selectedRecord && (access || selectedRecord!.contributorId === userProfile!.id) && dataSource.length>0 &&
        <Button type="primary" ghost loading={loadingDelete} onClick={handleFormDelete} icon={<DeleteOutlined />}>
          Delete
        </Button>
        }
      </Space>
      <FeeScheduleEditModal
        showModal={showModal} 
        handleCancel={handleCancel} 
        form={form} 
        handleOk={handleSave} 
        handleFormChange={handleFormChange} 
        codeEditEnable={codeEditEnable}
      />
      <FeeScheduleSaveAsModal
        showModal={showSaveAsModal} 
        handleCancel={handleSaveAsModalCancel} 
        form={saveAsForm} 
        handleOk={handleSaveAsModalOk} 
      />
    </>
  );
};

export default DownloadPage;
