import {
  Form,
  Input,
  Select,
  DatePicker,
} from 'antd';

import {INSURANCE_TYPE} from '../../configs/constants';

const { RangePicker } = DatePicker;

function FeeScheduleMeta (props: any) {
  const {form, handleMetaFormChange } = props;

  return (
    <>
      <Form
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        style={{ maxWidth: 800 }}
        onValuesChange={handleMetaFormChange}
      >
        <Form.Item 
          label="Type" 
          rules={[{ required: true, message: 'Please select a category' }]}
          name='insType'
        >
          <Select style={{ width: 150 }} 
            placeholder="Category">
            {INSURANCE_TYPE.map(type => (
              <Select.Option key={type.id} value={type.id}>{type.type}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item 
          label="Payor ID" 
          rules={[{ required: true, message: 'Please enter Payor ID.' }]}
          name='payorId'
        >
          <Input style={{ width: 150 }} />
        </Form.Item>
        <Form.Item 
          label="Carrier" 
          rules={[{ required: true, message: 'Please enter Insurance Carrier.' }]}
          name='insCarrier'
        >
          <Input />
        </Form.Item>
        <Form.Item 
          label="Plan Name" 
          rules={[{ required: true, message: 'Please enter Insurance Plan Name.' }]}
          name='insPlanName'
        >
          <Input />
        </Form.Item>
        <Form.Item 
          label="Group #" 
          rules={[{ required: true, message: 'Please enter Insurance Group #.' }]}
          name='insGroupNo'
        >
          <Input style={{ width: 150 }}/>
        </Form.Item>
        <Form.Item 
          label="Group Name" 
          rules={[{ required: true, message: 'Please enter Insurance Group Name.' }]}
          name='insGroupName'
        >
          <Input />
        </Form.Item>
        <Form.Item 
          label="Effective Date" 
          rules={[{ required: true, message: 'Please enter effective dates.' }]}
          name='effectiveDate'
        >
          <RangePicker />
        </Form.Item>
        <Form.Item label="Comments" name='comments'>
          <Input />
        </Form.Item>
      </Form>
    </>
  );
};

export default FeeScheduleMeta;