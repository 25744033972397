import {Avatar, 
        Drawer, 
        List, 
        Stack, 
        Toolbar, 
        ListItemButton,
        Divider } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';

// import assets from "../../assets";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";

import { mainAppContext } from "../../App";

const Sidebar = () => {

  const {logOut, userProfile} = useContext(mainAppContext);

  const navigate = useNavigate();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: sizeConfigs.sidebar.width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: sizeConfigs.sidebar.width,
          boxSizing: "border-box",
          borderRight: "0px",
          backgroundColor: colorConfigs.sidebar.bg,
          color: colorConfigs.sidebar.color
        }
      }}
    >
      <List disablePadding>
        <Toolbar sx={{ marginBottom: "20px" }}>
          <Stack
            sx={{ width: "100%" }}
            direction="row"
            justifyContent="center"
          >
            <Avatar src={userProfile?.userImage} />
          </Stack>
        </Toolbar>
        {appRoutes.map((route, index) => (
          route.sidebarProps && route.access.includes(userProfile!.userType) ? (
            route.child ? (
              <SidebarItemCollapse item={route} key={index} />
            ) : (
              <SidebarItem item={route} key={index} />
            )
          ) : null
        ))}
        <Divider 
          sx={{
              borderColor: "#8A8383"
          }}
        />
        <ListItemButton
            sx={{
              paddingLeft: "80px"
            }}
            onClick={(event) => {
              logOut();
              navigate("/");
            }}
        >
          Sign Out
        </ListItemButton>
      </List>
    </Drawer>
  );
};

export default Sidebar;