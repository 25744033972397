import PropTypes from 'prop-types';
import { useState } from 'react';

import {
  Form,
  Input,
  Modal,
} from 'antd';

import { isNumeric } from '../../util/util';

function FeeScheduleEditModal(props: any)  {
  const { 
    showModal, 
    handleCancel, 
    form, 
    handleOk, 
    handleFormChange, 
    codeEditEnable,
    } = props;

  const [isFormValid, setIsFormValid] = useState(false);

  const handleOkClick = () => {
    if (!isFormValid) {
      return;
    }
    // submit the form
    form.submit();
    handleOk()
  };

  const handleModalFormChange = () => {
    // check if the form is valid
    // somehow I cannot get form.validatefields work
    // had to do custom check
    const isRequired = form.getFieldValue('code') !== '' && 
      form.getFieldValue('description') !== '' 
      && form.getFieldValue('fee1') !== '';

    const isNumber = isNumeric(form.getFieldValue('fee1')) &&
    (!form.getFieldValue('fee2') || isNumeric(form.getFieldValue('fee2'))) && 
    (!form.getFieldValue('fee3') || isNumeric(form.getFieldValue('fee3')));

    setIsFormValid(isRequired && isNumber);

    handleFormChange();
  };

  // Custom validator function to check if input is a number
  const validateNumber = (_: any, value: any) => {
    const regex = /^[0-9]+(\.[0-9]{1,2})?$/; // Regular expression to match numeric values with up to 2 decimal places
    if (value && !regex.test(value)) {
      return Promise.reject('Please enter a valid number');
    }
    return Promise.resolve();
  };

  return (
    <Modal
    title="Edit Fee Schedule"
    open={showModal}
    onCancel={handleCancel}
    onOk={handleOkClick}
  >
    <Form
      form={form}
      layout="horizontal"
      style={{ maxWidth: 400 }}
      onValuesChange={handleModalFormChange}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 14 }}
      size="small"
      labelAlign='right'
    >
      <Form.Item name="category" label="Category" rules={[{ max: 30, message: 'Less than 30 characters'}]}>
        <Input />
      </Form.Item>
      <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Please enter description' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="abbr" label="Abbr">
        <Input />
      </Form.Item>
      <Form.Item name="code" 
        label="Code" 
        rules={[{ required: true, max: 30, message: 'Please enter valid code, less than 30 characters' }]}>
        <Input disabled={!codeEditEnable} />
      </Form.Item>
      <Form.Item name="fee1" label="Fee 1" rules={[{ required: true, message: 'Please enter valid fee' , validator: validateNumber}]}>
        <Input />
      </Form.Item>
      <Form.Item name="fee2" label="Fee 2" rules={[{ required: false, message: 'Please enter valid fee' , validator: validateNumber}]}>
        <Input />
      </Form.Item>
      <Form.Item name="fee3" label="Fee 3" rules={[{ required: false, message: 'Please enter valid fee' , validator: validateNumber}]}>
        <Input />
      </Form.Item>
    </Form>
  </Modal>
  );
};

FeeScheduleEditModal.propTypes = {
    showModal: PropTypes.bool.isRequired, 
    handleCancel: PropTypes.func.isRequired, 
    form: PropTypes.object.isRequired, 
    handleOk: PropTypes.func.isRequired, 
    handleFormChange: PropTypes.func.isRequired,
    codeEditEnable: PropTypes.bool.isRequired,
  };

export default FeeScheduleEditModal;