import { useContext } from 'react';
import { mainAppContext } from '../../App';

import { Typography } from 'antd';
const { Text, Paragraph} = Typography;

const HomePage = () => {
  const {userProfile} = useContext(mainAppContext);

  return (
    <>
      <Paragraph>
        <Text strong>Welcome {userProfile?.fullName}</Text>
      </Paragraph>
      <Paragraph>
        Our platform serves as a hub for doctors and office managers to share and reuse fee schedules with ease. As a user, you can actively contribute to the platform by creating a new fee schedule to share with others. Alternatively, you can search the existing fee schedules for one that suits your needs.
      </Paragraph>
      <Paragraph>
        Our platform provides a streamlined solution to help medical professionals manage their fee schedules efficiently. With our user-friendly interface, you can easily navigate and find the fee schedule you require. By collaborating and sharing fee schedules, we hope to create a more efficient and standardized healthcare industry.
      </Paragraph>
      <Paragraph>
        Join our community today and take advantage of our platform's benefits.
      </Paragraph>
    </>
  );
};

export default HomePage;