import { useContext, useState} from 'react';
import { Button, Popconfirm, Table, Typography, Divider, 
          Form, Space, Upload, message } from 'antd';
import { RcFile } from "antd/lib/upload/interface";
import * as Papa from 'papaparse';
import { saveAs } from 'file-saver';
import axios from 'axios';

import { mainAppContext } from '../../App';
import { 
  FeeScheduleType, 
  areAllFeeScheduleType, 
 } from '../../types/feeSchedule';
import usePrompt from '../../components/layout/usePrompt';
import { ADMIN, EMPLOYEE, CUSTOMER, PAID_CUSTOMER } from '../../configs/constants';
import FeeScheduleEditModal from './FeeScheduleEditModal';
import UploadInstruction from './UploadInstruction';
import FeeScheduleMeta from './FeeScheduleMeta';
import { isNumeric } from '../../util/util';
import { apiUrlBase } from '../../configs/constants';

function ProcessPage() {
  const {user, userProfile} = useContext(mainAppContext);
  const [fileList, setFileList] = useState<any>([]);
  const access = [ADMIN, EMPLOYEE, CUSTOMER, PAID_CUSTOMER].includes(userProfile!.userType);

  const data : FeeScheduleType[] = [];

  data.map((obj: FeeScheduleType) => {
    obj.key = obj.code;
    return obj;
  }) 

  const [dataSource, setDataSource] = useState(data);
  const [editingKey, setEditingKey] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [codeEditEnable, setCodeEditEnable] = useState(false);

  const config = {
    headers: {
      Authorization: `Bearer ${user}`,
      'Content-Type': 'application/json',
    },
  };

  const handleDelete = (key: string) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);
    newData.splice(index, 1);
    setDataSource(newData);
    if (access) setIsDirty(true)
  };

  const handleEdit = (key: string) => {
    form.resetFields();
    setEditingKey(key);
    setCodeEditEnable(false);
    setShowModal(true);
    form.setFieldsValue(dataSource.find((item) => item.key === key));
  };

  const handleCancel = () => {
    setEditingKey('');
    setShowModal(false);
  };

  const handleSave = () => {
    const newData = [...dataSource];
    const key = editingKey;
    
    if (key && key !== '') {
      const index = newData.findIndex((item) => key === item.key);
      const item = newData[index];
      const updatedItem = { ...item, ...form.getFieldsValue() };
      newData.splice(index, 1, updatedItem);
    } else {
      // if the code was there, we update the code instead of add
      const index = newData.findIndex((item) => form.getFieldValue('code') === item.code);
      if (index !== -1) {
        const item = newData[index];
        const updatedItem = { ...item, ...form.getFieldsValue() };
        newData.splice(index, 1, updatedItem);
      } else {
        const newItem = form.getFieldsValue();
        newItem.key = form.getFieldValue('code');
        newData.push(newItem);
      }
    }

    setDataSource(newData);
    setEditingKey('');
    setShowModal(false);
  };

  const [form] = Form.useForm();
  const [formMeta] = Form.useForm();

  let columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Abbr',
      dataIndex: 'abbr',
      key: 'abbr',
    },
    {
      title: 'Code',
      key: 'code',
      dataIndex: 'code',
    },
    {
      title: 'Fee 1',
      key: 'fee1',
      dataIndex: 'fee1',
    },
    {
      title: 'Fee 2',
      key: 'fee2',
      dataIndex: 'fee2',
    },
    {
      title: 'Fee 3',
      key: 'fee3',
      dataIndex: 'fee3',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => {
        return (
          <span>
            <Button type="link" onClick={() => handleEdit(record.key)}>
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this row?"
              onConfirm={() => handleDelete(record.key)}
            >
              <Button type="link" danger>
                Delete
              </Button>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const handleFormChange = () => {
    if (access) setIsDirty(true);
  };

  const handleMetaFormChange = () => {
    if (access) setIsDirty(true);
  };

  const handleFormSave = async () => {
    if (!access) return false;

    const metaValue = formMeta.getFieldsValue();

    //do thorough check
    if (!metaValue.insType) {
      message.error('Please choose insurance type.');
      return false;
    }

    if (!metaValue.payorId) {
      message.error('Please enter payor ID.');
      return false;
    }

    if (!metaValue.payorId) {
      message.error('Please enter payor ID.');
      return false;
    }

    if (!metaValue.insCarrier) {
      message.error('Please enter insurance carrier.');
      return false;
    }
    
    if (!metaValue.insPlanName) {
      message.error('Please enter insurance plan name.');
      return false;
    }

    if (!metaValue.insGroupNo) {
      message.error('Please enter insurance group #.');
      return false;
    }

    if (!metaValue.insGroupName) {
      message.error('Please enter insurance group name.');
      return false;
    }

    if (!metaValue.effectiveDate) {
      message.error('Please enter insurance effective date.');
      return false;
    }

    if (dataSource.length <1) {
        message.error('There is no fee schedule code.');
        return false;
    }

    for (var i = 0; i < dataSource.length; i++) {
      if (!dataSource[i].description || dataSource[i].description ==='') {
        message.error(`Please enter fee schedule description in table row ${i+1}`);
        return false;
      }
      if (!dataSource[i].code || dataSource[i].code ==='') {
        message.error(`Please enter fee schedule code in table row ${i+1}`);
        return false;
      }
      if (!dataSource[i].fee1 || !isNumeric(dataSource[i].fee1)) {
        message.error(`Please enter valid fee 1 in table row ${i+1}`);
        return false;
      }
      if (dataSource[i].fee2 && !isNumeric(dataSource[i].fee2)) {
        message.error(`Please enter fee schedule fee 2 in table row ${i+1}`);
        return false;
      }
      if (dataSource[i].fee3 && !isNumeric(dataSource[i].fee3)) {
        message.error(`Please enter fee schedule fee 3 in table row ${i+1}`);
        return false;
      }
    }

    const dataSourceCopy = dataSource.filter(function (data) {
      delete data.key;
      return true;
    });

    setLoadingSave(true);

    // save to database
    const postData = {
      feeSchedules: dataSourceCopy,   
      feeScheduleMeta: metaValue,  
      contributor: userProfile!.screenId,
      contributorId: userProfile!.id,
    };

    try {
      const apiUrl = `${apiUrlBase}/feeschedules`;
      await axios.put(apiUrl, postData, config);
      setIsDirty(false);
      setLoadingSave(false);
    } catch (error) {
      console.log(error)
      message.error('error occured while saving');
      setLoadingSave(false);
    }
  };

  const handleDownload = () => {
    setLoadingDownload(true);
    // Convert data to CSV format
    const dataSourceCopy = dataSource.filter(function (data) {
      delete data.key;
      return true;
    });

    const csv = Papa.unparse(dataSourceCopy);

    // Save CSV file using file-saver library
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'data.csv');
    setLoadingDownload(false);
  };

  const handleAdd = () => {
    setEditingKey('');
    setCodeEditEnable(true);
    form.resetFields();
    setShowModal(true);
  };

  const convertCsvToJson = (csvString: string) => {
    const lines = csvString.split("\n");
    const headers = lines[0].replace(/(\r\n|\n|\r)/gm, "").split(",");
    const result = [];

    for (let i = 1; i < lines.length; i++) {
      const obj: any = {};
      if (lines[i].length<1) continue;
      const currentLine = lines[i].replace(/(\r\n|\n|\r)/gm, "").split(",");

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentLine[j];
      }

      // change all fees into number
      if (obj.fee1) { obj.fee1 = parseFloat(obj.fee1);}
      if (obj.fee2) { obj.fee2 = parseFloat(obj.fee2);}
      if (obj.fee3) { obj.fee3 = parseFloat(obj.fee3);}

      result.push(obj);
    }

    return result;
  };


  const handleFileUpload = async (file: RcFile) => {
    const fileType = file.type;
    const allowedTypes = ['text/csv', 'application/vnd.ms-excel'];

    if (!allowedTypes.includes(fileType)) {
      message.error('File type not supported');
      return false;
    }

    setLoadingUpload(true);
    try {
      let isFileFormatValid = false;
      let jsonObject;
      const reader = new FileReader();
      reader.readAsText(file as Blob);
      reader.onload = () => {
        const csvString = reader.result as string;
        jsonObject = convertCsvToJson(csvString);
        isFileFormatValid=areAllFeeScheduleType(jsonObject);

      if (isFileFormatValid) {
        message.success('File uploaded successfully');
        setFileList([file]);
        const data = jsonObject as FeeScheduleType[];
        // add key 
        data.map((obj: FeeScheduleType) => {
          if (!obj.key) {obj.key = obj.code};
          return obj;
        }) 
        setDataSource(data);
        setIsDirty(true);
      } else {
         message.error('Invalid File Format'); 
         setFileList([]);
         setLoadingUpload(false);
         return false;
      }
    };
    } catch (error) {
      message.error('Invalid File Format');
      setFileList([]);
      setLoadingUpload(false);
      return false;
    }
    setLoadingUpload(false);
  };

  const handleRemove = () => {
    setFileList([]);
  };

  const promptMessage = "Are you sure you want to leave? You have unsaved changes.";
  usePrompt(promptMessage, isDirty);

  return (
    <>
      <Typography.Title level={5}>Create New Fee Schedule</Typography.Title>
      <Divider />
      { dataSource.length===0 && <UploadInstruction/>}
      { dataSource.length>0 && <FeeScheduleMeta form={formMeta} handleMetaFormChange={handleMetaFormChange}/>}
      { dataSource.length>0 && <Table dataSource={dataSource} columns={columns} />}
      <Space>
        <Upload 
          accept=".csv, application/vnd.ms-excel" 
          beforeUpload={handleFileUpload}
          fileList={fileList}
          onRemove={handleRemove}
        >
          <Button type="primary" ghost loading={loadingUpload}>Upload</Button>
        </Upload>
        {dataSource.length>0 && <Button type="primary" ghost loading={loadingDownload} onClick={handleDownload}>
          Download
        </Button>}
        {true && <Button type="primary" ghost onClick={handleAdd}>
          Add a Code
        </Button>}
        {dataSource.length>0 && access && 
        <Button type="primary" ghost disabled={!isDirty} loading={loadingSave} onClick={handleFormSave}>
          Save
        </Button>
        }
      </Space>
      <FeeScheduleEditModal
        showModal={showModal} 
        handleCancel={handleCancel} 
        form={form} 
        handleOk={handleSave} 
        handleFormChange={handleFormChange} 
        codeEditEnable={codeEditEnable}
      />
    </>
  );
};

export default ProcessPage;
